import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import {
  Text,
  Tooltip,
  IconButton,
  Loader,
  Box,
  Button,
  Paragraph,
  Image,
} from "../commonElements"
import { Download } from "@styled-icons/fa-solid"
import styled from "styled-components"
import TarotFlipCoverImage from "../../images/tarot/Flip-Cover.jpg"
import BackgroundStars from "../../images/tarot/background-stars.png"
import { CardsData } from "./tarotCards"
// import { toPng } from "html-to-image"
import TarotIcon from "../../images/tools/tarot-image.png"
import html2canvas from "html2canvas"

const QuestionBox = styled(Box)`
  margin: ${props => (props.showBorder ? "0.5rem 0rem 1rem" : "0rem")};
  border-top: ${props =>
    props.showBorder ? "2px solid rgb(205, 205, 205)" : "none"};
  border-bottom: ${props =>
    props.showBorder ? "2px solid rgb(205, 205, 205)" : "none"};
  min-width: 350px;
  @media screen and (max-width: 447px) {
    min-width: 90%;
  }
`

const GuessParagraph = styled(Paragraph)`
  overflow: ${props => props.overflow};
  text-overflow: ${props => props.textOverflow};
  white-space: ${props => props.whiteSpace};
  max-height: ${props => props.maxHeight};
  min-height: ${props => props.minHeight};
  margin-left: ${props => props.ml};
  margin-bottom: ${props => props.mb};
  text-align: center;
  margin-right: 0.5rem;
`

const Stars = styled.div`
  background: ${_ => `transparent url(${BackgroundStars}) repeat`};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: -1;
`

const Stars2 = styled.div`
  background-image: radial-gradient(
    ellipse at bottom,
    rgb(27, 39, 53) 10%,
    rgb(9, 10, 15, 0.8) 100%
  );
  // background-image:
  // radial-gradient(white, rgba(255,255,255,.15) 2px, transparent 40px),
  // radial-gradient(white, rgba(255,255,255,.1) 1px, transparent 30px),
  // radial-gradient(white, rgba(255,255,255,.07) 2px, transparent 40px),
  // radial-gradient(rgba(255,255,255,.3), rgba(255,255,255,.07) 2px, transparent 30px),
  // radial-gradient(ellipse at bottom,rgb(27, 39, 53) 10%,rgb(9, 10, 15, 0.8) 100%);
  // background-size: 100%;
  // background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: -2;
  border-radius: 1rem;
`

const CardModal = styled(Box)`
  color: white;
  height: fit-content;
  min-height: 100%;
  @media screen and (max-width: 600px) {
    padding: 2rem 1.5rem 3rem;
    display: block;
  }
`

const ModalImage = styled(Image)`
  background-blend-mode: multiply;
  // box-shadow: 0 4px 8px 2px rgba(255,255,255,.6);
  object-fit: initial;
  margin-left: auto;
  margin-right: auto;
  max-height: 400px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const ModalImageOverlay = styled.div`
  position: absolute;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const ModalImageSmall = styled(Image)`
  background-blend-mode: multiply;
  // box-shadow: 0 2px 9px 2px rgba(255,255,255,.6);
  object-fit: initial;
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`

const ModalImageSmallOverlay = styled.div`
  position: absolute;
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
`

const ModalButton = styled(Button)`
  width: fit-content;
  height: fit-content;
  border: none;
  background-image: ${props =>
    `linear-gradient( 100deg,${props.color1} 20%,${props.color2} 100%`});
  @media screen and (max-width: 447px) {
    max-width: 70%;
    padding: 12px 12px;
  }
`

const ModalDownloadButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`

const ModalDownloadFooter = styled.div`
  position: absolute;
  bottom: 0;
  flex-direction: row;
  display: flex !important;
  & > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0rem;
    }
  }
`

const ModalIconButton = styled(IconButton)`
  color: white;
`

const ModalContainer = styled(Box)`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1.5rem 1.5rem;
  margin-bottom: 3rem;
  @media screen and (max-width: 600px) {
    grid-template-columns: none;
    grid-gap: 0rem 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
`

const ModalParagraphTitle = styled(Paragraph)`
  margin-bottom: 1rem;
  @media screen and (max-width: 600px) {
    font-size: 2.2rem;
    text-align: center;
  }
`

const ModalAnswer = styled(Paragraph)`
  margin-bottom: ${props => props.mb};
  margin-top: ${props => props.mt};
  @media screen and (max-width: 600px) {
    font-size: 1.8rem;
    font-weight: bold;
  }
`

const ModalParagraph = styled(Paragraph)`
  margin-bottom: ${props => props.mb};
  margin-top: ${props => props.mt};
  @media screen and (max-width: 600px) {
    font-size: 1.6rem;
  }
`

const ModalSeparator = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 1.45rem;
  @media screen and (max-width: 600px) {
    margin-top: 1.45rem;
    border-bottom: none;
  }
`

const TarotCardModal = ({ cardNumber, flipOn, loaded, setCardNumber }) => {
  const questionOn = useSelector(state => state.tarot.question.on)
  const textPersist = useSelector(state => state.tarot.text)
  const [text, setText] = useState("")
  const toolColorsPersist = useSelector(state => state.general.colors.array)
  const [toolColors, setToolColors] = useState(["#3D3D3D", "#3D3D3D"])
  const cardsData = CardsData.data
  const [id, setId] = useState("")
  const [title, setTitle] = useState("")
  const [answer, setAnswer] = useState("")
  const [description, setDescription] = useState([])

  useEffect(() => {
    if (toolColorsPersist !== undefined) {
      setToolColors(toolColorsPersist)
    } else {
      setToolColors(["#59569D", "#F25292"])
    }
  }, [toolColorsPersist])

  const handleResize = () => {
    document.getElementById("tarot-tool-box").style.minHeight = 0
    handleResizeToolBox()
  }

  useEffect(() => {
    handleFetchStarsBackground()
    window.addEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    handleResize()
  }, [questionOn])

  useEffect(() => {
    if (textPersist !== text) {
      setText(textPersist)
    }
  }, [textPersist])

  useEffect(() => {
    if (cardNumber !== 0) {
      setId(cardsData[cardNumber - 1].id)
      setTitle(cardsData[cardNumber - 1].title)
      setAnswer(cardsData[cardNumber - 1].answer)
      setDescription(cardsData[cardNumber - 1].description.split("<br/>"))
    } else {
      handleResizeToolBox()
    }
  }, [cardNumber])

  useEffect(() => {
    handleResizeToolBox()
  }, [flipOn])

  const handleResizeToolBox = () => {
    if (description !== "") {
      // so that advertisement below the tool box will not be covered
      document.getElementById("tarot-tool-box").style.minHeight = `${
        document.getElementById("tarot-card-slip").offsetHeight
      }px`
    } else if (cardNumber === 0) {
      // so that the tool box back to initial height
      document.getElementById("tarot-tool-box").style.minHeight = 0
    }
  }

  const handleFetchStarsBackground = async () => {
    const imageBlob = await getImageBlob(BackgroundStars)
    const imageSource = window.URL.createObjectURL(imageBlob)
    document.getElementById(
      "tarot-card-stars"
    ).style.background = `transparent url(${imageSource}) repeat`
  }

  const getImageBlob = async url => {
    const imageResponse = await cacheFirst({
      request: url,
    })
    return imageResponse.blob()
  }

  const cacheFirst = async ({ request }) => {
    try {
      const responseFromCache = await caches.match(request)
      if (responseFromCache) {
        return responseFromCache
      }
      const responseFromNetwork = await fetch(request)
      if (responseFromNetwork.ok) {
        putInCache(request, responseFromNetwork.clone())
        return responseFromNetwork
      }
    } catch (error) {
      const responseFromNetwork = await fetch(request)
      return responseFromNetwork
    }
  }

  const putInCache = async (request, response) => {
    const cache = await caches.open("TarotCardV1")
    await cache.put(request, response)
  }

  const handleDownloadImage = () => {
    document.getElementById("tarot-card-button").style.visibility = "hidden"
    document.getElementById("tarot-card-download-button").style.visibility =
      "hidden"
    document.getElementById("tarot-card-download-footer").style.visibility =
      "visible"
    document.getElementById("tarot-card-slip").style.backgroundColor =
      "rgb(255,255,255)"
    document.getElementById("tarot-card-slip").style.borderRadius = "0rem"
    document.getElementById("tarot-card-stars2").style.borderRadius = "0rem"

    // toPng(document.getElementById("tarot-card-slip")).then(function (dataUrl) {
    //     var link = document.createElement("a")
    //     link.download = "FlipSimu-Yes-Or-No-Tarot-Card.png"
    //     link.href = dataUrl
    //     link.click()
    //     document.getElementById("tarot-card-button").style.visibility = "visible"
    //     document.getElementById("tarot-card-download-button").style.visibility = "visible"
    //     document.getElementById("tarot-card-download-footer").style.visibility = "hidden"
    //     document.getElementById("tarot-card-slip").style.backgroundColor = "rgba(0,0,0,0.8)"
    // })
    exportAsImage(
      document.getElementById("tarot-card-slip"),
      "FlipSimu-Yes-Or-No-Tarot-Card"
    )
    document.getElementById("tarot-card-button").style.visibility = "visible"
    document.getElementById("tarot-card-download-button").style.visibility =
      "visible"
    document.getElementById("tarot-card-download-footer").style.visibility =
      "hidden"
    document.getElementById("tarot-card-slip").style.backgroundColor = ""
    document.getElementById("tarot-card-slip").style.borderRadius = "1rem"
    document.getElementById("tarot-card-stars2").style.borderRadius = "1rem"
  }

  const exportAsImage = async (element, imageFileName) => {
    const canvas = await html2canvas(element)
    const image = canvas.toDataURL("image/png", 1.0)
    downloadImage(image, imageFileName)
  }

  const downloadImage = (blob, fileName) => {
    var link = document.createElement("a")
    link.download = fileName
    link.href = blob
    link.click()
    link.remove()
  }

  return (
    <CardModal
      id="tarot-card-slip"
      style={
        cardNumber !== 0 && !flipOn ? { display: "flex" } : { display: "none" }
      }
      borderRadius="1rem"
      alignItems="center"
      width="100%"
      alignSelf="stretch"
      justifyContent="flex-start"
      positionBox="absolute"
      zIndex="7"
      topP="0px"
      bottomP="0px"
      padding="1.5rem 2rem 2.5rem"
    >
      <Stars id="tarot-card-stars" />
      <Stars2 id="tarot-card-stars2" />
      <QuestionBox showBorder={questionOn && text !== ""}>
        <GuessParagraph
          fontSize="2rem"
          mb="0rem"
          ml="0rem"
          minHeight={questionOn && text !== "" ? "3rem" : "0rem"}
          overflow="hidden"
          maxHeight="3rem"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {questionOn ? text : ""}
        </GuessParagraph>
      </QuestionBox>
      <ModalParagraph
        textAlign="center"
        fontSize="1.8rem"
        mb="0rem"
        mt="0.5rem"
      >
        Your card is ......
        {/* {id} */}
      </ModalParagraph>
      <ModalContainer
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        positionBox="relative"
        width="100%"
        mt="2rem"
        maxWidth="80rem"
      >
        <Box justifyContent="flex-start" alignItems="center">
          <Box justifyContent="center" alignItems="center">
            <ModalImage
              id="tarot-card-image"
              borderRadius="0.5rem"
              src={TarotFlipCoverImage}
              alt="Tarot Card"
            />
            {!loaded && (
              <ModalImageOverlay>
                <Loader />
              </ModalImageOverlay>
            )}
          </Box>
        </Box>
        <Text>
          <ModalParagraphTitle fontSize="2.4rem">{title}</ModalParagraphTitle>
          <Box justifyContent="center" alignItems="center">
            <ModalImageSmall
              id="tarot-card-image-small"
              borderRadius="0.5rem"
              height="200px"
              width="auto"
              src={TarotFlipCoverImage}
              alt="Tarot Card"
            />
            {!loaded && (
              <ModalImageSmallOverlay>
                <Loader />
              </ModalImageSmallOverlay>
            )}
          </Box>
          <ModalSeparator>
            <ModalAnswer fontSize="2rem" mb="1rem">
              {answer}
            </ModalAnswer>
          </ModalSeparator>
          <ModalParagraph>
            {description.map(desc => (
              <React.Fragment>
                {desc}
                <br />
              </React.Fragment>
            ))}
          </ModalParagraph>
        </Text>
      </ModalContainer>
      <Box
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        positionBox="relative"
        width="100%"
      >
        <ModalButton
          id="tarot-card-button"
          mt="1.5rem"
          color1={toolColors[0]}
          color2={toolColors[1]}
          onClick={() => {
            setCardNumber(0)
          }}
        >
          PICK ANOTHER CARD
        </ModalButton>
        <ModalDownloadButton id="tarot-card-download-button">
          <Tooltip content="Download as Image" direction="right">
            <ModalIconButton
              onClick={event => {
                event.preventDefault()
                handleDownloadImage()
              }}
              icon={<Download size={22} />}
            />
          </Tooltip>
        </ModalDownloadButton>
        <ModalDownloadFooter
          id="tarot-card-download-footer"
          style={{ visibility: "hidden" }}
        >
          <Image height="22" src={TarotIcon} />
          <Paragraph textAlign="center">
            Tarot Flip Simu by FlipSimu.com
          </Paragraph>
        </ModalDownloadFooter>
      </Box>
    </CardModal>
  )
}

export default TarotCardModal
